import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BlogList from '../components/BlogList';

const IndexPage = ({data, location}) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;
  console.log(posts);
  const visiblePosts = posts.filter(post => post.frontmatter.visible === true);
  return (
    <div>
      <Layout pageTitle="Home Page">
        <BlogList posts={visiblePosts}/>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          slug
          hero_image_alt
          hero_image_credit_link
          hero_image_credit_text
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          read_time
          visible
        }
        id
        excerpt
        fields {
          timeToRead {
            minutes
            text
            time
            words
          }
        }
      }
    }
  }
`

export default IndexPage