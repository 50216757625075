import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogList = ({ posts }) => {
  return (
    <section className="blog-list px-3 py-5 p-md-5">
      <div className="container single-col-max-width">
        {posts.map((post) => {
          const heroImage = getImage(post.frontmatter.hero_image)!;
          return (
            <div className="item mb-5">
              <div className="row g-3 g-xl-0">
                <div className="col-2 col-xl-3">
                  <GatsbyImage
                    image={heroImage}
                    alt={post.frontmatter.hero_image_alt}
                    className="img-fluid post-thumb"
                  />
                </div>
                <div className="col">
                  <Link to={`/blog/${post.frontmatter.slug}`}>
                    <h3 className="title mb-1">{post.frontmatter.title}</h3>
                  </Link>
                  <div className="meta mb-1">
                    <span className="date">{post.frontmatter.date}</span>
                    {/* <span className="time">{post.frontmatter.read_time}</span> */}
                  </div>
                  <div className="intro">{post.excerpt}</div>
                  <Link
                    to={`/blog/${post.frontmatter.slug}`}
                    className="text-link"
                  >
                    Read more &rarr;
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default BlogList;
